import React, { useRef, useEffect } from "react"
import { graphql, Link } from "gatsby"

import WidgetContainer from "../components/WidgetContainer/WidgetContainer"
import Layout from "../components/Layout/Layout"
import SEO from "../components/seo"

import NotFoundImg from "../images/404-static-image.png";

import "./404.css"

const NotFoundPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <WidgetContainer stylesClass="not_found_container">
        <div className="not_found">
          <SEO title="404: Not found" />
          <div className="not_found__img">
            <img src={NotFoundImg} alt="404: Not found"/>
          </div>
          <div className="not_found__content">
            <h1>Uh-oh!</h1>
            <p>
              We're having trouble finding the page you're looking for. Try
              going back to the
              <Link to="/">homepage</Link> or see our
              <a href="https://developer.phonepe.com">Help Center</a>
              for more information.
            </p>
          </div>
        </div>
      </WidgetContainer>
    </Layout>
  )
}

export default NotFoundPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
